import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="Second slide"
        />       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="Second slide"
        />       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/17.jpg"
          alt="Second slide"
        />       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/26.jpg"
          alt="Second slide"
        />       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/53.jpg"
          alt="Second slide"
        />       
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/54.jpg"
          alt="Second slide"
        />       
      </Carousel.Item> */}
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/55.jpg"
          alt="Second slide"
        />       
      </Carousel.Item>
 
    </Carousel>
  );
}

export default PowerCarousel;