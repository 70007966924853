import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCheckCircle} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs'
import VisionCarousel from '../components/Vision Carousel';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Kas Electrical and Plumbing Services';

},[]);


  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'><strong>Kas Electrical and Plumbing Services</strong> is a trusted one-stop centre for
all your electrical, IT and Plumbing needs. With a team backed by over 20 years’ experience, particularly in
commercial, industrial, civil, hospital-based and residential
electrical systems, solar power backup and irrigation system
installation, IT system networking, Air Conditioner, Generator &
Refrigerator system supply, maintenance and repair solutions
</p>
<p className='home-p'>
We are well coordinated with our partners in light of up-to-date
technology, annual product performance comparison and efficient
material sourcing to provide our clientele with the best value for
money on all deliveries of service and supplies
We endeavor to grow our team expertise and fill knowledge gaps
with evolving technologies in order to consistently meet our clients’
ever-growing needs.</p>



 
        </div>

    </section>
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.663), rgba(0, 0, 0, 0.663)), url("./images/34.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We pledge to maintain the highest level of quality assurance and ensure unequalled customer satisfaction.

</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

   <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>Our Scope of Work</h2>
    <div className='line1'> 
    {/* <div className='line2'></div> */}
    </div>
   
   
   
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Electrical Works</h3>
          <img src='./images/6a.jpg' className='home-service-image'></img>
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            <Link to='electrical-works' className='home-link' onClick={scrollFunc}>See Details</Link>

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Solar Power Services</h3> 
          <img src='./images/17.jpg' className='home-service-image'></img>
          <Link to='solar-power-services' className='home-link' onClick={scrollFunc}>See Details</Link>
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
       
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Plumbing Services</h3>
          <img src='./images/27.jpg' className='home-service-image'></img>
          <Link to='plumbing-services' className='home-link' onClick={scrollFunc}>See Details</Link>
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Irrigation Services</h3>
          <img src='./images/29.jpg' className='home-service-image'></img>
          {/* <Link to='#' className='home-link'>See Details</Link> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>IT Services</h3>
          <img src='./images/28.jpg' className='home-service-image'></img>
          <Link to='/it-services' className='home-link' onClick={scrollFunc}>See Details</Link>
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Mechanical Repairs</h3>
          <img src='./images/30.jpg' className='home-service-image'></img>
          {/* <Link to='#' className='home-link'>See Details</Link> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
         
       
      
       


 
{/* 
        <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck>  </span>
          </div>
          <h3 className='home-service-title'>Certifications</h3>
          <p>
            As a company we are registered with the relevant authorities and our entire workforce holds the appropriate qualifications and certifications.
            </p>
        </div> */}

    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        </div>


   



  </section>
 <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2> */}
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section>

   {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Objectives</h2>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our people are the greatest asset and we shall endeavor to continuously develop
our human resource to create a competitive advantage in our expertise.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our processes guarantee quality and sustainability. We shall build world
class processes and implement certified Integrated Management Systems.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To become a preferred EPC Contractor, we need to boost our brand visibility
through targeted marketing activities.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Improve our customer service through talent acquisition and close interaction and
collaboration with our customers.</p>
                     </div>

                     
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our Plant & Machinery are state of the art and we shall endeavor to continuously
improve our asset base.</p>
                     </div>
<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <VisionCarousel></VisionCarousel>
               

           </article>




       </div>

  </section> */}

  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
    
  
    </>
  )
}

export default Home