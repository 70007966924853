import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare,FaYoutube, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';
import {TfiYoutube} from 'react-icons/ti'

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/28.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

 



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='about' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>
                         <div className='footer-link'>
                              <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='clients' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'> Our Clients</p></Link> 
                        </div> 
                        <div className='footer-link'>
                              <Link to='contact' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Write To Us</p></Link> 
                        </div>                  
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Location</h3>
                      <p>
                       Plot 1778, Old Jinja Road, next to Namilyango S.S, Seeta, Mukono, Uganda
                        </p>
                        
                     

                    </div>

                   

                    
                      

             </div>


                   <div className='footer-inner-content'>


                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                     +256779 526279 <br></br>+256753 294483
                      
                      
                      </p>

                    </div>

                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  
                   <p>
                        info@kaselectricalandplumbing.com<br></br>
                   kaselec@gmail.com

{/* rejoconstructions500@gmail.com


rejoengineeringltd@gmail.com */}
                    </p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                            <a href='#' target="_blank" rel='noreferrer'>  <span>
                        <FaYoutube></FaYoutube>
                                 </span></a>
                                <a href='#' target="_blank" rel='noreferrer'> <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span></a>
                          <a href='#' target="_blank" rel='noreferrer'>     <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>
                                 </a>

                            {/* <a href='#' target="_blank" rel='noreferrer'>       <span>
<FaLinkedin></FaLinkedin>
                                 </span>  
                                 </a> */}
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Kas Electrical and Plumbing Services | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer