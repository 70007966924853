import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Projects | Clayeford Construction Services Ltd';

},[]);

  return (
    <section className='section-cover team-cover team-cover1' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Our Projects</h2>




  <table className='team-table team-table1'>
     
     <tr>
      <th>S/No.</th>
      <th>Project Title</th>
    </tr>
     <tr>
      <td>1. </td>
      <td>Rehabilitation of 31km Kyahi Kyabagamba Road  </td>
</tr>

 <tr>
      <td>2. </td>
      <td>Seedbed preparation for Uganda Prisons  </td>
</tr>

 <tr>
      <td>3. </td>
      <td>Renovation of Bushenyi District Local Government Adminitsration Block Phase Two  </td>
</tr>

 <tr>
      <td>4. </td>
      <td>Renovation of a Residence at Jomayi  </td>
</tr>

<tr>
      <td>5. </td>
      <td>Compound Design and Paving for Counsel Chris Bakiza</td>
</tr>

<tr>
      <td>6. </td>
      <td>Construction of a Residential House in Lubowa </td>
</tr>
      

<tr>
      <td>7. </td>
      <td>Renovation of Uganda Driver Licensing System Offices </td>
</tr>

<tr>
      <td>8. </td>
      <td>Construction of a Hardware at Kakiri - Wakiso District </td>
</tr>

<tr>
      <td>9. </td>
      <td>Supply of furniture to Equal Opporunities Commission </td>
</tr>

    
    

  </table>


         </div>

           
    </section>
  )
}

export default Team